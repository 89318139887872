.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-element {
    width: 80%;
    height: auto;
    cursor: pointer;
  }
  
  .video-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 2em;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .top-right-controls {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  }
  
  .control-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
  }
  
  .bottom-controls {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .bottom-controls button {
    font-size: 1.5em;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .video-element:playing + .video-title,
  .video-element:paused + .video-title {
    opacity: 1;
  }
  
  .video-element + .video-title {
    opacity: 0;
  }
  